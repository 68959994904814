<template>
  <div class="no-scroll-content flex flex-col border border-solid d-theme-border-grey-light d-theme-dark-bg">
    <!------------ box header ------------->
    <div class="flex-none">
      <div class="flex flex-wrap space-x-3 p-2 vx-card shadow-none">
        <div class="flex items-center">
          <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
          <vs-select class="vs-select-small" :value="null" v-model="filter.id_proyek">
            <vs-select-item class="vs-select-small" text="Semua"/>
            <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
          </vs-select>
        </div>
        <div class="flex items-center">
          <vs-input class="w-full" type="text" placeholder="Search" v-model="filter.search" @keyup.enter="getData"/>
        </div>
        <div class="flex items-center">
          <vs-button class="px-7 py-3 text-xs text-center" color="primary" :disabled="loading.filtering" @click="refresh">
            {{ loading.filtering ? 'Loading...' : 'Filter' }}
          </vs-button>
        </div>
      </div>
      <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start space-x-3 px-2 h-8">
          <p class="text-xs font-medium text-dark text-bold">{{ this.data.items.length }} Result</p>
          <QueryEditor v-if="$can('view_query')" code="AP_SALDO_AWAL"/>
        </div>
      </div>
    </div>

    <!----------- box content ------------->
    <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
      <div class="box-content-height overflow-auto">
        <table class="table-fixed table-sticky border-collapse w-full">
          <!-----------TABLE HEAD------------>
          <thead>
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-6">#</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-16">Action</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Nomor</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-16">Proyek</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-24">Tgl Due</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Tipe</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Nama</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Keterangan</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Total Nilai</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Total Paid</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Total Balance</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Created By</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Created At</th>
            </tr>
          </thead>
          <!-----------TABLE BODY------------>
          <tbody>
            <tr v-for="(item, index) in data.items" :key="index">
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ index + 1 }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer xs" v-if="$can('delete_ap_saldo_awal')">
                  <vs-button class="py-2 px-3" size="small" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item class="p-1" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.nomor }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ item.kode_proyek }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ item.tgl_due }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.tipe_kreditur }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.nama_kreditur }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.keterangan }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total_paid | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total_balance | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.created_by }}</td>
              <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.created_at }}</td>
            </tr>
          </tbody>
          <!-----------TABLE FOOT------------>
          <tfoot v-if="data.items.length > 0">
            <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right">{{ detailsSummary.grandTotal | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right">{{ detailsSummary.grandTotalPaid | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right">{{ detailsSummary.grandTotalBalance | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!------------ box footer ------------->
    <div class="flex-none">
      <div class="flex flex-wrap items-center p-2 justify-between">
        <div class="flex items-center space-x-2">
          <vs-button v-if="$can('create_ap_saldo_awal')" color="success" size="small" class="px-3" icon-pack="feather" icon="icon-plus" @click="modalAdd.active = true">Tambah</vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <ApSaldoAwalAdd :isActive.sync="modalAdd.active" @success="refresh"/>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ApSaldoAwalRepository from '@/repositories/accounting/ap-saldo-awal-repository'
import _ from 'lodash'

export default {
  name: 'ApSaldoAwal',
  components: {
    ApSaldoAwalAdd: () => import('@/views/pages/accounting/account-payable/ap-saldo-awal/ApSaldoAwalAdd'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  mounted () {
    this.getProyek()
  },
  computed: {
    detailsSummary () {
      return {
        grandTotal: _.sumBy(this.data.items, item => parseFloat(item.total)),
        grandTotalPaid: _.sumBy(this.data.items, item => parseFloat(item.total_paid)),
        grandTotalBalance: _.sumBy(this.data.items, item => parseFloat(item.total_balance))
      }
    }
  },
  data () {
    return {
      proyeks: [],
      loading: {
        filtering: false
      },
      modalAdd: {
        active: false
      },
      filter: {
        id_proyek: null,
        search: null
      },
      data: {
        items: []
      }
    }
  },
  methods: {
    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.filtering = true

      const params = {
        id_proyek: this.filter.id_proyek,
        search: this.filter.search
      }
      ApSaldoAwalRepository.get(params)
        .then(response => {
          this.data.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.filtering = false
        })
    },

    delete (id) {
      ApSaldoAwalRepository.delete(id)
        .then(response => {
          this.refresh()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.notifyErrorUsingDialog(error.response.data.errors)
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
    },

    refresh () {
      this.data.items = []
      this.getData()
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus data?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 283px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 306px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 252px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 269px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 292px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 238px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 251px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 274px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 220px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 206px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 229px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 175px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 101;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 101;
}
</style>
